import React from 'react';
import Search from '../../Search';
import Icon from '../../Icon';
import Switch from '../../Switch';
import Paginator from '../../Paginator';
import { Anchor } from '../../Buttons';
import Layout from '../../Layout';
import Table, { Cell } from '../../Table';
import useSearch from '../../hooks/useSearch';
import classNames from 'classnames';

const Index = ({ users }) => {
  const { search, setSearch } = useSearch({
    search: (new URLSearchParams(location.search)).get('search') || '',
    include_trashed: (new URLSearchParams(location.search)).get('include_trashed') === 'true',
  });

  return <Layout>
    <div className="text-sm">
      <h2 className="text-3xl tracking-wide mb-6">Users</h2>
      <div className="my-6 flex justify-between text-sm">
        <div className="flex items-center">
          <Search onChange={value => setSearch('search', value)} value={search.search}/>
          <label className="ml-4 inline-flex items-center">
            <span className="mr-2 text-sm">Include deleted users</span>
            <Switch onChange={checked => setSearch('include_trashed', checked)}
                    value={search.include_trashed || false}
                    height={22}
                    width={43}/>
          </label>
        </div>
        <Anchor href="/admin/users/create">Add new user</Anchor>
      </div>

      <div className="bg-white shadow-md rounded-lg mb-6 text-sm">
        <Table headers={['Name', 'Email']}>
          <tbody>
          {users.data.map((user, index) => (
            <tr key={index}
                className={classNames('border-t hover:bg-gray-100 focus-within:bg-gray-100' , {
                  'bg-red-50': !!user.deleted_at
                })}>
              <Cell href={`/admin/users/${user.uuid}/edit`}
                    primary>
                <span>{user.name}</span>
                {user.deleted_at &&
                  <span className="ml-2 rounded-full px-2 text-xs text-red-600 bg-red-200">Inactive</span>
                }
                {!!user.is_service_account &&
                  <span className="ml-2 rounded-full px-2 text-xs text-blue-600 bg-blue-200">Service Account</span>
                }
              </Cell>
              <Cell href={`/admin/users/${user.uuid}/edit`}>{user.email}</Cell>
              <Cell href={`/admin/users/${user.uuid}/edit`}><Icon name="arrowRight" className="h-4 w-4"/></Cell>
            </tr>
          ))}
          </tbody>
        </Table>
      </div>
      {users.meta.last_page !== 1 && <Paginator pagination={users.meta}/>}
    </div>
  </Layout>;
};

export default Index;
