import React from 'react';
import { render, waitFor } from '@testing-library/react';
import Index from './Index';
import useSearch from '../../hooks/useSearch';
import userEvent from '@testing-library/user-event';

jest.mock('../../hooks/useSearch');
jest.mock('../../hooks/useDebounce', () => (value) => value);

describe('Pages/Users/Index', () => {
  const setSearch = jest.fn();
  let user;

  beforeEach(() => {
    window.Claims = [];

    useSearch.mockReturnValue({
      setSearch,
      search: {
        search: 'initial search term',
      },
    });

    user = userEvent.setup();
  });

  it('displays users', () => {
    const { getByText } = render(
      <Index
        users={{
          data: [{
            name: 'Wod',
            email: 'nunya@biz.ness',
            deleted_at: '2023-12-21',
            is_service_account: true,
          }],
          meta: {},
        }}
      />
    );

    expect(getByText('Wod')).toBeInTheDocument();
    expect(getByText('nunya@biz.ness')).toBeInTheDocument();
    expect(getByText('Inactive')).toBeInTheDocument();
    expect(getByText('Service Account')).toBeInTheDocument();
  });

  it('links to editing the user', () => {
    const { getByText } = render(
      <Index
        users={{
          data: [{
            uuid: '9abf76ff-03d1-42a0-8bae-8ca199893c5d',
            name: 'Wod',
            email: 'nunya@biz.ness',
          }],
          meta: {},
        }}
      />
    );

    const editUrl = '/admin/users/9abf76ff-03d1-42a0-8bae-8ca199893c5d/edit';

    expect(getByText('Wod').parentElement).toHaveAttribute('href', editUrl);
    expect(getByText('nunya@biz.ness')).toHaveAttribute('href', editUrl);
  });

  it('renders pagination links', () => {
    const { getByLabelText } = render(
      <Index
        users={{
          data: [],
          meta: {
            current_page: 1,
            last_page: 2,
          },
        }}
      />
    );

    expect(getByLabelText('Page 1 is your current page')).toBeInTheDocument();
    expect(getByLabelText('Page 2')).toBeInTheDocument();
    expect(getByLabelText('Next page')).toBeInTheDocument();
  });

  it('searches for users', async () => {
    const { getByPlaceholderText } = render(
      <Index
        users={{
          data: [],
          meta: {},
        }}
      />
    );

    expect(getByPlaceholderText('Search...')).toHaveValue('initial search term');

    await user.clear(getByPlaceholderText('Search...'));
    await user.type(getByPlaceholderText('Search...'), 'new term');

    await waitFor(() => {
      expect(setSearch).toHaveBeenCalledWith('search', 'new term');
    });
  });

  it('preloads the search hook', async () => {
    window.history.pushState({}, '', '?search=forthings&include_trashed=true');

    render(
      <Index
        users={{
          data: [],
          meta: {},
        }}
      />
    );

    expect(useSearch).toHaveBeenCalledWith({
      search: 'forthings',
      include_trashed: true,
    });
  });

  it('includes deleted users', async () => {
    const { getByText } = render(
      <Index
        users={{
          data: [],
          meta: {},
        }}
      />
    );

    await user.click(getByText('Include deleted users'));

    await waitFor(() => {
      expect(setSearch).toHaveBeenCalledWith('include_trashed', true);
    });
  });
});
