import React, {useState} from 'react';

const useGroupedClaims = claims => {
    const [groupedClaims] = useState(claims.reduce((acc, claim) => {
        const [system] = claim.claim.split(':');
        acc[system] = acc[system] || [];
        acc[system].push(claim)
        return acc;
    }, {}));

    return groupedClaims;
};

export default useGroupedClaims;
