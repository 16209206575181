import React from 'react';
import FlowerIcon from './FlowerIcon';
import classNames from 'classnames';


const variants = {
  primary: 'text-white bg-blue-500 border-blue-500',
  secondary: 'text-blue-500 border-blue-500'
};

const Button = React.forwardRef(({
  onClick = () => {},
  children,
  loading,
  variant = 'primary',
  ...extraProps
}, ref) => (
  <button
    ref={ref}
    onClick={onClick}
    className={classNames(
      'text-sm py-2 px-4 w-full rounded-full flex items-center justify-center border focus:outline-none focus:ring disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap',
      variants[variant],
    )}
    {...extraProps}
  >
    {variant === 'primary' &&
      (loading ? <FlowerIcon animate={loading} key="loading" /> : <FlowerIcon key="stop" />)
    }
    <div className="mx-2" />
    {children}
  </button>
));

export default Button;