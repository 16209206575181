import React from 'react';
import useGtm from './hooks/useGtm';

const Tracked = ({label, children}) => {
  const ref = useGtm(label);

  return React.cloneElement(children, {ref});
};

Tracked.defaultProps = {
  label: '',
};

export default Tracked;