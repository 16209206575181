import React, { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

const useAjax = (url) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const get = (uuid = null) => {
        setLoading(true);

        if (!uuid) {
            return axios.get(url)
                .finally(() => setLoading(false));
        }

        return axios.get(`${url}/${uuid}`)
                .finally(() => setLoading(false));
    }

    const save = (data = {}, uuid = null) => {
        if (loading) {
          return;
        }

        setLoading(true);
        setErrors({});

        const request = {
            method: uuid ? 'put' : 'post',
            url: uuid ? `${url}/${uuid}` : url,
        };

        return axios[request.method](request.url, data)
            .catch(err => {
                console.error(err);
                if (err.response.status !== 422) {
                    toast('Something went wrong :(', {
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.TOP_CENTER,
                    })
                }

                setErrors(err.response.data.errors || []);

                throw err;
            })
            .finally(() => setLoading(false));
    }

    const request = (method, url, data = {}) => {
        setLoading(true);
        setErrors({});

        return axios[method](url, data)
            .catch(err => {
                if (err.response.status !== 422) {
                    toast('Something went wrong :(', {
                        type: toast.TYPE.ERROR,
                        position: toast.POSITION.TOP_CENTER,
                    })
                }

                setErrors(err.response.data.errors || []);

                throw err;
            })
            .finally(() => setLoading(false));
    }

    return {loading, errors, get, save, request};
};

export default useAjax;
