import React, {useLayoutEffect, useState} from 'react';
import Button from './Button';
import Card from './Card';
import usePostMessage from '../../hooks/usePostMessage';

const NoMatches = ({provider, email}) => {
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    usePostMessage({ resize: document.body.scrollHeight });
  }, []);

  const createAccount = async () => {
    setLoading(true);

    await axios.post(window.location);

    usePostMessage('User authenticated');
  };

  return <Card>
    <div className="mb-6 text-center text-gray-700">
      <p className="mb-2 text-xl">Have we met yet?</p>
      <p className="leading-snug text-gray-600">
        We couldn't find any accounts linked to the email address we got from {provider}; {email}.
      </p>
    </div>
    <Button onClick={createAccount} loading={loading}>Sign up for a FREE account</Button>
    <div className="my-2"/>
    <Button variant="outlined"
            loading={loading}
            href="/login/account">Go back to login</Button>
  </Card>
};

export default NoMatches;