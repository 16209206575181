import React from 'react';
import { motion } from 'framer-motion';

const FlowerIcon = ({ animate = false }) => (
  <motion.svg
    width="20"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    transition={{ duration: 2, repeat: Infinity }}
    animate={animate ? { rotate: 720 } : { rotate: 0 }}
  >
    <path
      d="M5.823 10c0-.76.253-1.519.506-2.025L1.14 4.937C.633 5.823.253 6.962 0 7.975L3.924 9.62v.633L0 11.9c.253 1.139.633 2.152 1.14 3.164l5.19-3.038c-.254-.506-.507-1.266-.507-2.025zm10.253.253V9.62L20 7.975a13.47 13.47 0 00-1.14-3.165l-5.19 3.038c.38.633.507 1.266.507 2.025 0 .76-.253 1.646-.633 2.152l5.19 3.038c.506-1.012 1.013-2.025 1.14-3.164l-3.798-1.646zM10 5.823c.76 0 1.519.253 2.025.506l3.038-5.19C14.177.633 13.038.253 11.9 0l-1.646 3.924H9.62L7.975 0C6.962.253 5.823.633 4.937 1.14l2.911 5.19c.633-.254 1.393-.507 2.152-.507zm0 8.354c-.76 0-1.519-.253-2.025-.506l-3.038 5.19C5.949 19.367 6.962 19.873 8.1 20l1.646-3.924h.633L12.025 20a13.47 13.47 0 003.165-1.14l-3.038-5.19c-.633.254-1.393.507-2.152.507z"
      fill="#FF612D"
    />
  </motion.svg>
);

export default FlowerIcon;
