import React from 'react';
import { render, screen } from '@testing-library/react';
import NoMatches from './NoMatches';
import mockedAxios from 'axios';
import userEvent from '@testing-library/user-event';

jest.mock('axios', () => ({
  post: jest.fn().mockResolvedValue(),
}));

beforeEach(() => {
  window.axios = mockedAxios;
});

afterEach(() => {
  jest.clearAllMocks();
});

describe('Seeker/NoMatches', () => {
  it('allows you to create an account', async () => {
    render(<NoMatches provider="Test" email="test@mctest.com" />);

    await userEvent.click(screen.getByRole('button', { name: /sign up for a free account/i }));

    expect(mockedAxios.post).toHaveBeenCalledTimes(1);
  });

  it('allows you to cancel', () => {
    render(<NoMatches provider="Test" email="test@mctest.com" />);

    expect(screen.getByRole('link', { name: /go back to login/i })).toHaveAttribute('href', '/login/seeker');
  });

  it('the button need to become disabled after the user click the button once', async () => {
    render(<NoMatches provider="Test" email="test@mctest.com" />);

    await userEvent.click(screen.getByRole('button', { name: /sign up for a free account/i }));

    expect(screen.getByRole('button', { name: /sign up for a free account/i })).toBeDisabled()
  });
});
