import React from 'react';
import { render } from '@testing-library/react';
import Create from './Create';
import userEvent from '@testing-library/user-event';
import { createMockAxios } from '../../../helpers/MockAxios';
import { router } from '@inertiajs/react';

const mockAxios = createMockAxios();

describe('Pages/Users/Create', () => {
  let user;
  let routerSpy;

  beforeEach(() => {
    window.Claims = [];

    window.User = {
      uuid: '',
    };

    user = userEvent.setup();

    jest.clearAllMocks();

    routerSpy = jest.spyOn(router, 'visit').mockImplementation(() => {});

    mockAxios.resetHistory();
  });

  it('saves the users name, email and password', async () => {
    mockAxios.onPost()
      .reply(200, {
        data: {
          uuid: newUserUuid,
        }
      });

    const { getByPlaceholderText, getByText } = render(
      <Create
        route={storeRoute}
      />
    );

    await user.type(getByPlaceholderText('Name'), 'Ronald');

    await user.type(getByPlaceholderText('Email'), 'ron@swanson.com.au');

    await user.type(getByPlaceholderText('Password'), 'bacon');
    await user.type(getByPlaceholderText('Confirm password'), 'bacon');

    await user.click(getByText('Save'));

    expect(
      mockAxios.request('post', '/api/admin/users')
        .dataObject,
    ).toStrictEqual({
      name: 'Ronald',
      email: 'ron@swanson.com.au',
      password: 'bacon',
      password_confirmation: 'bacon',
    });

    expect(routerSpy).toHaveBeenCalledWith(`/admin/users/${newUserUuid}/edit`);
  });
});

const storeRoute = '/api/admin/users';

const newUserUuid = '893e35fd-0af6-4b9b-8358-b2f82e11dc0b';
