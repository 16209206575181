import React, {useLayoutEffect} from 'react';
import Button from './Button';
import Card from './Card';
import usePostMessage from '../../hooks/usePostMessage';

const Denied = () => {

  useLayoutEffect(() => {
    usePostMessage({ resize: document.body.scrollHeight });

    if (window.opener) {
      window.resizeTo(400, 284);
    }
  }, []);

  return <Card>
    <div className="mb-6 text-center text-gray-700">
      <p className="mb-2 text-lg text-xl">Uh Oh!</p>
      <p className="mb-1 leading-snug text-gray-600">
        Looks like you changed your mind and didn't give us access to your account.
      </p>
      <p className="leading-snug text-gray-600">
        It's not a problem, but without access to your account we cannot continue.
      </p>
    </div>
    <Button onClick={() => window.close()}>Close</Button>
  </Card>
};

export default Denied;