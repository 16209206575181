import React, { useState, useEffect } from 'react';
import {Button} from "./Buttons";
import useAjax from "./hooks/useAjax";
import BeatLoader from "react-spinners/BeatLoader";
import Icon from "./Icon";

const RefreshAccessToken = ({uuid}) => {

    const ajax = useAjax();

    const [locked, setLocked] = useState(true);
    const [token, setToken] = useState(null);
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        !locked && setTimer(setTimeout(() => setLocked(true), 3000));

        return () => timer && clearTimeout(timer);
    }, [locked]);

    const handleClick = () => {
        if (locked) {
            return setLocked(false);
        }

        refreshToken();
    }

    const refreshToken = () => {
        ajax.request('put', `/api/admin/users/${uuid}/refresh-token`)
            .then(response => {
                setToken(response.data.data.accessToken);
                setLocked(true);
            });
    }

    return <>
        {token &&
            <div>
                <hr className="border-t mb-4" />
                <p className="mb-1 text-xs font-light text-gray-600 uppercase">New Token</p>
                <p className="break-words">{token}</p>
                <hr className="border-t my-4" />
            </div>
        }

        <div className="flex justify-end">
            <Button onClick={handleClick} type={locked || ajax.loading ? 'primary' : 'danger'}>
                {ajax.loading
                    ? <BeatLoader color="#EBF8FF" size={7}/>
                    : <div className="flex items-center">
                        <Icon name={locked ? 'locked' : 'unlocked'} className="h-4 w-4"/>
                        <span className="ml-2">
                            { locked
                                ? 'Refresh Token'
                                : 'I\'m sure I want to refresh the token'
                            }
                        </span>
                </div>
                }
            </Button>
        </div>
    </>;
};

export default RefreshAccessToken;
