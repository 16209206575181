import React from 'react';
import AsyncSelect from 'react-select/async';
import tailwindConfig from '../../../tailwind.config';
import resolveConfig from "tailwindcss/resolveConfig";
import axios from 'axios';

const tailwind = resolveConfig(tailwindConfig);

const OrganisationSelect = (props) => {

    const mapOrganisationsToSelect = organisations => organisations.map(org => ({
        value: org.id,
        label: org.name
    }));

    const loadOrganisations = inputValue => {
        return axios.get('/api/admin/organisations', {
            params: {search: inputValue, orderBy: 'name'},
            headers: {Accept: 'application/json'},
        }).then(response => mapOrganisationsToSelect(response.data.data))
            .catch(err => {
                console.error(err)
                return [];
            });
    };

    const loadOptions = (inputValue) => loadOrganisations(inputValue);

    const customStyles = {
        control: (base, state) => ({
            ...base,
            '&:hover': {},
            color: state.isFocused ? tailwind.theme.colors.blue['100'] : tailwind.theme.colors.blue['500'],
            boxShadow: 'none',
            borderWidth: tailwind.theme.borderWidth['default'],
            borderColor: state.isFocused ? tailwind.theme.colors.blue['500'] : tailwind.theme.colors.gray['300']
        }),
        placeholder: (base) => ({
            ...base,
            color: tailwind.theme.colors.gray['500']
        }),
        option: (base, {isFocused, isSelected, isDisabled}) => ({
            ...base,
            backgroundColor: isDisabled
                ? tailwind.theme.colors.blue['100']
                : isSelected
                    ? tailwind.theme.colors.blue['500']
                    : isFocused
                        ? tailwind.theme.colors.blue['400']
                        : tailwind.theme.colors.white,
            color: isDisabled
                ? tailwind.theme.colors.blue['300']
                : isFocused || isSelected
                    ? tailwind.theme.colors.blue['100']
                    : tailwind.theme.colors.black,

        }),
        multiValue: (styles) => ({
            ...styles,
            backgroundColor: tailwind.theme.colors.blue['200'],
        }),
        multiValueLabel: (styles) => ({
            ...styles,
            color: tailwind.theme.colors.blue['600'],
        }),
        multiValueRemove: (styles) => ({
            ...styles,
            color: tailwind.theme.colors.blue['600'],
            ':hover': {
                backgroundColor: tailwind.theme.colors.blue['600'],
                color: tailwind.theme.colors.blue['200'],
            },
        }),
    };

    return (
        <div className="w-full">
            <AsyncSelect
                defaultValue={mapOrganisationsToSelect(props.userOrganisations)}
                cacheOptions
                isMulti
                loadOptions={loadOptions}
                defaultOptions
                styles={customStyles}
                onChange={organisations => props.onChange(organisations || [])}
            />
        </div>
    );
};

export default OrganisationSelect;
