import { useEffect, useState } from 'react';
import axios from 'axios';

const usePagination = (dataUrl, url) => {
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState({});
    const [isFetching, setIsFetching] = useState(false);
    const [cancel, setCancel] = useState(null);

    const retrieveItemsFromCache = (event) => setData(event.state);

    useEffect(() => {
        (async function fetchData() {
            const source = axios.CancelToken.source();

            cancel && cancel.cancel();

            setCancel(source);
            setIsFetching(true);

            const result = await axios.get(dataUrl, {
                params: { page },
                headers: {Accept: 'application/json'},
                cancelToken: source.token,
            }).catch(e => {
                setIsFetching(false);
                if (axios.isCancel(e)) {
                    return;
                }
                console.error(e);
            });

            const cleanup = () => source.cancel();

            if (!result) {
                return cleanup;
            }

            setData(result.data.data.map(item => {
                return {
                    ...item,
                    links: {
                        view: `${url}/${item.uuid}`,
                        edit: `${url}/${item.uuid}/edit`
                    }
                };
            }));

            setPagination(result.data.meta);

            setIsFetching(false);

            return cleanup;
        })()
    }, [page]);

    useEffect(() => {
        window.addEventListener('popstate', retrieveItemsFromCache);
        return () => window.removeEventListener('popstate', retrieveItemsFromCache)
    }, []);

    return { data, setPage, pagination, isFetching }
};

export default usePagination;
