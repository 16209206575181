import React from 'react';
import NavItems from './NavItems';

const Layout = ({children}) => {
    return <div className="flex min-h-screen bg-gray-100">
        <nav className="flex flex-col bg-blue-800 text-blue-100 shadow-lg">
            <div className="flex items-center px-10 h-16 shadow bg-blue-900">
                <h1 className="text-xl font-light leading-loose tracking-wide">
                    <a href="/">iSeekplant Login</a>
                </h1>
            </div>
            <NavItems/>
        </nav>
        <div className="flex-1 bg-gray-200">
            <div className="flex items-center justify-end bg-white h-16 shadow px-6">
                <div className="flex items-center">
                    {!!window.User?.name
                        ? <span>{window.User.name}</span>
                        : <a href="/login">Login</a>
                    }
                </div>
            </div>

            <div className="p-10 text-gray-900">
                {children}
            </div>
        </div>
    </div>
};

export default Layout;
