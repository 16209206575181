import React from 'react';
import classNames from 'classnames';

const Input = React.forwardRef(({id, label, helpText, error, ...extraProps}, ref) => {
  return <>
    <label htmlFor={id} className="block text-sm font-medium leading-5 text-gray-700">{label}</label>
    <div className="mt-2 relative rounded-md shadow-sm">
      <input id={id}
             name={id}
             className={classNames({
               'border border-gray-300 focus:border-brand-orange focus:outline-none focus:shadow-outline-orange rounded block w-full sm:text-sm sm:leading-5 py-4 px-5 disabled:bg-gray-200': true,
               'text-red-900 border-red-300 focus:border-red-300 focus:shadow-outline-red': !!error
             })}
             ref={ref}
             {...extraProps}/>
    </div>
    {!!error &&
      <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>{error}</p>
    }
    {!!helpText &&
      <p className="mt-2 text-sm text-gray-500" id={`${id}-description`}>{helpText}</p>
    }
  </>;
});

Input.defaultProps = {
  helpText: false
};

export default Input;