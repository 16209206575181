import React from 'react';
import ReactSwitch from 'react-switch';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';

const Switch = (props) => {
    const tailwind = resolveConfig(tailwindConfig);

    return <ReactSwitch
        checked={props.value}
        onChange={props.onChange}
        offColor={tailwind.theme.colors.gray['600']}
        onColor={tailwind.theme.colors.blue['500']}
        offHandleColor={tailwind.theme.colors.gray['100']}
        onHandleColor={tailwind.theme.colors.gray['100']}
        uncheckedIcon={false}
        checkedIcon={false}
        height={props.height}
        width={props.width}
    />
};

Switch.defaultProps = {
    onChange: () => {},
    height: 28,
    width: 56,
};

export default Switch;
