import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import OrganisationForm from '../../Forms/OrganisationForm';
import useAjax from '../../hooks/useAjax';
import Layout from '../../Layout';
import { router } from '@inertiajs/react';

const Create = ({route}) => {
  const ajax = useAjax(route);

  const onSubmit = data => {
    ajax.save(data)
      .then(response => {
        toast('Organisation created!', {
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER,
        });

        router.visit(`/admin/organisations/${response.data.data.uuid}/edit`);
      });
  };

  return <Layout>
    <div className="text-sm">
      <h2 className="text-3xl tracking-wide mb-10">Create a new organisation</h2>
      <div className="w-full max-w-lg bg-white shadow-md rounded-lg p-6">
        <OrganisationForm user={{}} onSubmit={onSubmit} loading={ajax.loading} errors={ajax.errors} showChangePassword={true}/>
      </div>
      <ToastContainer/>
    </div>
  </Layout>;
};

export default Create;
