import React, {useEffect, useState} from "react";
import Icon from "./Icon";
import { ClipLoader } from "react-spinners";
import useDebounce from "./hooks/useDebounce";

const Search = ({ onChange, isFetching, value }) => {
    const [localValue, setLocalValue] = useState(value);

    const debouncedValue = useDebounce(localValue, 500)

    useEffect(() => {
        onChange(debouncedValue);
    }, [debouncedValue]);

    return (
        <div className="relative text-gray-500 focus-within:text-blue-500">
            <div className="absolute left-0 ml-3" style={{
                top: '50%',
                transform: 'translateY(-50%)'
            }}>
                <Icon name="search" className="h-4 w-4"/>
            </div>
            <input type="text"
               value={localValue}
               onChange={e => setLocalValue(e.target.value)}
               className="w-64 text-gray-900 py-2 pr-6 pl-10 border border-transparent focus:border-blue-300 rounded-full shadow focus:outline-none"
               placeholder="Search..."/>
           <div className="absolute top-0 right-0 mt-3 mr-3">
            {isFetching ? <ClipLoader size="1rem" color="#90CDF4" /> : ''}
           </div>
        </div>
    );
};

export default Search;
