import React from 'react';
import { render,screen } from '@testing-library/react';
import Denied from './Denied';
import userEvent from '@testing-library/user-event';

describe('Account/Denied', () => {
  it('renders', () => {
    render(<Denied />);
  });

  it('closes the window when the button is clicked', async () => {
    render(<Denied />);

    global.close = jest.fn();

    await userEvent.click(screen.getByRole('button', {  name: /close/i}));

    expect(global.close).toHaveBeenCalledTimes(1);
  });
});