import React, { useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import UserForm from '../../Forms/UserForm'
import ActiveToggle from '../../ActiveToggle'
import useAjax from '../../hooks/useAjax'
import useClaims from "../../hooks/useClaims";
import RefreshAccessToken from '../../RefreshAccessToken'
import Layout from '../../Layout'
import Roles from './Roles'
import { router as Inertia } from '@inertiajs/react'
import Claims from './Claims';

const Edit = ({ user, claims, ...props }) => {

  const [showChangePassword, setShowChangePassword] = useState(false);
  const userAjax = useAjax(props.route);
  const userClaims = useClaims();

  const canManage = () => {
    if (userClaims.hasClaim('login:root')) {
      return true;
    }
    return user.uuid !== window.User.uuid;
  }

  const onSubmit = (data) => {
    Object.keys(data).forEach((key) => (key.includes('password') && data[key] === "") && delete data[key]);

    if (!window.Claims.includes('login:manage-organisations')) {
      delete data['organisations'];
    }

    userAjax.save(data, user.uuid)
      .then(() => {
        toast('Account updated!', {
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER,
        });

        setShowChangePassword(false);

        Inertia.reload();
      })
  };

  return <Layout>
    <div className="text-sm">
      <h2 className="text-2xl tracking-wide mb-10">{user.name}</h2>
      <div className="flex -mx-2">
        <div className="mb-8 w-1/3 px-2 mr-6">
          <div className="bg-white shadow-md rounded-lg p-6">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-xl tracking-wide">Edit account</h3>
              {canManage() && window.Claims.includes('login:manage-users') &&
                <ActiveToggle active={!user.deleted_at} route={`/api/admin/users/${user.uuid}/toggle-active`}/>
              }
            </div>
            <UserForm
              user={user}
              onSubmit={onSubmit}
              loading={userAjax.loading}
              errors={userAjax.errors}
              showChangePassword={showChangePassword}
              setShowChangePassword={setShowChangePassword}
            />
          </div>
        </div>
        {!!user.is_service_account &&
          <div className="mb-8 w-full max-w-lg">
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl tracking-wide mb-2">Bearer Token</h3>

              <p className="mb-2">
                This is a service account and therefore has a bearer token allowing it to authenticate and query
                against this login service.
              </p>
              <p className="mb-4">
                You can refresh the token, but doing so will <b>invalidate</b> the existing token. Refreshing
                the token and not updating the relevant service <b>may break functionality</b>.
              </p>

              <RefreshAccessToken uuid={user.uuid}/>
            </div>
          </div>
        }
        {canManage() && userClaims.hasAnyClaim(['login:root', 'login:manage-roles']) &&
          <div className="w-2/3 px-2">
            <Roles user={user} organisations={user.organisations}/>
          </div>
        }
      </div>
      {canManage() && userClaims.hasAnyClaim(['login:root', 'login:manage-permissions']) &&
        <div className="mb-8 w-full max-w-screen-lg">
          <div className="bg-white shadow-md rounded-lg p-6">
            <h3 className="text-xl tracking-wide mb-6">Edit permissions</h3>
              <Claims claims={claims} user={user}/>
          </div>
        </div>
      }
      <ToastContainer/>
    </div>
  </Layout>;
};

Edit.defaultProps = {
  user: {},
  claims: null,
};

export default Edit;
