import React, { useState } from 'react';
import { Button } from '../Buttons';
import BeatLoader from 'react-spinners/BeatLoader';
import classNames from 'classnames';
import useClaims from '../hooks/useClaims';
import OrganisationSelect from '../OrganisationSelect';

const UserForm = ({user, onSubmit, loading, errors, showChangePassword, setShowChangePassword, showOrganisations}) => {
  const [data, setData] = useState({
    name: user.name,
    email: user.email,
    password: '',
    password_confirmation: '',
    organisations: user.organisations?.map(org => org.id),
  });

  const claims = useClaims();

  const canManage = () => {
    if (claims.hasClaim('login:root')) {
      return true;
    }

    return user.uuid !== window.User.uuid;
  }

  const handleOrganisationChange = (organisations) => setData({
    ...data,
    organisations: organisations.map(org => org.value),
  });

  const updateData = (property) => {
    return (e) => setData({
      ...data,
      [property]: e.target.value,
    });
  }

  return <>
    <div className=" mb-4">
      <div className="flex items-center">
        <label className="inline-block w-20 text-xs font-light text-gray-600 uppercase" htmlFor="name">Name</label>
        <input type="text"
               placeholder="Name"
               name="name"
               defaultValue={data.name || ''}
               onChange={updateData('name')}
               className={classNames({
                 'block border focus:outline-none rounded w-64 px-2 py-1': true,
                 'border-gray-300 focus:border-blue-500': !errors.name,
                 'border-red-500 text-red-500': !!errors.name,
               })}
        />
      </div>
      { !!errors.name &&
        <p className="ml-20 text-xs font-light italic text-red-500 font-light">{errors.name[0]}</p>
      }
    </div>
    <div className="mb-4">
      <div className="flex items-center">
        <label className="inline-block w-20 text-xs font-light text-gray-600 uppercase" htmlFor="email">Email</label>
        <input type="text"
               placeholder="Email"
               name="email"
               defaultValue={data.email || ''}
               onChange={updateData('email')}
               className={classNames({
                 'block border focus:outline-none rounded w-64 px-2 py-1': true,
                 'border-gray-300 focus:border-blue-500': !errors.email,
                 'border-red-500 text-red-500': !!errors.email,
               })}
        />
      </div>
      { !!errors.email &&
        <p className="ml-20 text-xs font-light italic text-red-500 font-light">{errors.email[0]}</p>
      }
    </div>

    { showChangePassword ? <>
      <hr className="border-t my-4" />
      <div className="mb-4">
        <div className="flex items-center">
          <label className="inline-block w-20 text-xs font-light text-gray-600 uppercase" htmlFor="password">Password</label>
          <input type="password"
                 placeholder="Password"
                 name="password"
                 onChange={updateData('password')}
                 className={classNames({
                   'block border focus:outline-none rounded w-64 px-2 py-1': true,
                   'border-gray-300 focus:border-blue-500': !errors.password,
                   'border-red-500 text-red-500': !!errors.password,
                 })}
          />
        </div>
        { !!errors.password &&
          <p className="ml-20 text-xs font-light italic text-red-500 font-light">{errors.password[0]}</p>
        }
      </div>
      <div className="mb-4">
        <div className="flex items-center">
          <label className="inline-block w-20 text-xs font-light text-gray-600 uppercase" htmlFor="password_confirmation">Confirm Password</label>
          <input type="password"
                 placeholder="Confirm password"
                 name="password_confirmation"
                 onChange={updateData('password_confirmation')}
                 className={classNames({
                   'block border focus:outline-none rounded w-64 px-2 py-1': true,
                   'border-gray-300 focus:border-blue-500': !errors.password,
                   'border-red-500 text-red-500': !!errors.password,
                 })}
          />
        </div>
      </div>
    </> : <button className="ml-20 text-blue-500 underline" onClick={() => setShowChangePassword(true)}>Change password</button>
    }

    { canManage() && showOrganisations && user.uuid && claims.hasAnyClaim(['login:manage-organisations', 'login:root']) && <>
      <hr className="border-t my-4" />
      <div className="mb-4">
        <label className="block mb-1 text-xs font-light text-gray-600 uppercase" htmlFor="password">Organisations</label>
        <OrganisationSelect userOrganisations={user.organisations} onChange={handleOrganisationChange}/>

        { !!errors.password &&
          <p className="ml-32 text-xs font-light italic text-red-500 font-light">{errors.password[0]}</p>
        }
      </div>
    </>
    }

    <div className="flex justify-end">
      <Button onClick={() => onSubmit(data)} className="w-20 justify-center">
        { loading
          ? <BeatLoader color="#EBF8FF" size={7}/>
          : <span>Save</span>
        }
      </Button>
    </div>
  </>
};

UserForm.defaultProps = {
  showOrganisations: true
};

export default UserForm;
