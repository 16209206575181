import React from 'react';
import { render, screen } from '@testing-library/react';
import ConfirmSocialLink from './ConfirmSocialLink';
import userEvent from '@testing-library/user-event';
import { router as mockedInertia } from '@inertiajs/react';

jest.mock('@inertiajs/react', () => ({
  router: {
    post: jest.fn().mockResolvedValue(),
  }
}));

describe('Account/ConfirmSocialLink', () => {
  it('renders', () => {
    render(<ConfirmSocialLink provider="Test" errors={{}} />);
  });

  it('sends your password when you click the confirm button', async () => {
    render(<ConfirmSocialLink provider="Test" errors={{}} />);

    await userEvent.type(screen.getByLabelText(/iSeekplant Password/i), 'secret');

    await userEvent.click(screen.getByRole('button', {  name: /link my test account/i}));

    expect(mockedInertia.post).toHaveBeenCalledTimes(1);
    const [, formData] = mockedInertia.post.mock.calls[0];
    expect(formData.get('password')).toEqual('secret');
  });

  it('displays an error message if your password is incorrect', () => {
    render(<ConfirmSocialLink provider="Test" errors={{password: 'Ruh roh'}} />);

    expect(screen.getByText(/ruh roh/i)).toBeInTheDocument();
  });

  it('takes you back to the login page if you cancel', async () => {
    Object.defineProperty(window, 'location', {
      value: {
        href: "http://test.com"
      }
    });

    render(<ConfirmSocialLink provider="Test" errors={{}} />);

    await userEvent.click(screen.getByRole('button', {  name: /cancel/i}));

    expect(window.location.href).toEqual('/login/account');
  });
});