import React from 'react';
import { Button } from '../../Buttons';
import useAjax from '../../hooks/useAjax';
import { toast } from 'react-toastify';
import { router as Inertia } from '@inertiajs/react';

const Roles = ({user, organisations}) => {

  const ajax = useAjax();

  const handleSubmit = async e => {
    e.preventDefault();
    const response = await ajax.request('post', `/admin/users/${user.uuid}/roles`, new FormData(e.target))
      .catch(err => console.error(err));

    if (response?.status !== 200) {
      return;
    }

    toast('Roles updated!', {
      type: toast.TYPE.SUCCESS,
      position: toast.POSITION.TOP_CENTER,
    });

    Inertia.reload();
  };

  return <>
    <div className="mb-8 w-full max-w-screen-lg">
      <div className="bg-white shadow-md rounded-lg p-6">
        <h3 className="text-xl tracking-wide mb-6">Edit Roles</h3>
        <form onSubmit={handleSubmit}>
          <input type="hidden" name="_method" value="PUT"/>
          {
            organisations.map(organisation => (
              <div key={organisation.uuid}>
                <h3 className="mb-2 text-xs font-light text-gray-600 uppercase">{organisation.name}</h3>

                {organisation.roles.map(role => (
                  <div className="flex items-center p-1 mb-2" key={role.uuid}>
                    <label className="inline-flex items-center">
                      <input type="checkbox"
                             name="roles[]"
                             value={role.uuid}
                             defaultChecked={user.roles?.some(({uuid}) => uuid === role.uuid)}/>
                      <span className='ml-2 text-sm'>{role.name}</span>
                    </label>
                  </div>
                ))}
              </div>
            ))
          }

          <div className="flex justify-end">
            <Button className="w-20 justify-center" loading={ajax.loading}>
              <span>Save</span>
            </Button>
          </div>
        </form>
      </div>
    </div>
  </>;
};

export default Roles;
