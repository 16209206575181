import React, { useEffect, useState } from 'react';
import { router as Inertia } from '@inertiajs/react';

const useSearch = defaultSearch => {

    const [hasLoaded, setHasLoaded] = useState(false);
    const [search, setSearch] = useState(defaultSearch);

    useEffect(() => {
        setTimeout(() => setHasLoaded(true), 0);
    }, []);

    useEffect(() => {
        if (!hasLoaded) {
            return;
        }

        let searchParams = new URLSearchParams(location.search);

        Object.keys(search)
            .forEach(key => searchParams.set(key, search[key]));

        Inertia.replace(`${location.origin}${location.pathname}?${searchParams.toString()}`, {
            preserveState: true,
        })
    }, [search]);

    return {
        search,
        setSearch: (key, value) => setSearch({
            ...search,
            [key]: value,
        })
    }
};

export default useSearch;
