import React from 'react';
import { render, waitFor } from '@testing-library/react';
import Edit from './Edit';
import userEvent from '@testing-library/user-event';
import { createMockAxios } from '../../../helpers/MockAxios';

const mockAxios = createMockAxios();

describe('Pages/Organisations/Edit', () => {
  let user;

  beforeEach(() => {
    window.Claims = [];

    window.User = {
      uuid: '',
    };

    user = userEvent.setup();

    jest.clearAllMocks();

    mockAxios.resetHistory();

    mockAxios.onGet()
      .reply(200, {
        data: [],
        meta: {},
      });
  });

  it('shows the organisation name', async () => {
    const { getByText } = render(
      <Edit
        organisation={organisationStub}
        users={usersStub}
      />
    );

    await waitFor(() => {
      expect(getByText("Mulligan's")).toBeInTheDocument();
    });
  });

  it('prefills the organisation name and description into the form fields', async () => {
    const { getByPlaceholderText } = render(
      <Edit
        organisation={organisationStub}
        users={usersStub}
      />
    );

    await waitFor(() => {
      expect(getByPlaceholderText('Name')).toHaveValue("Mulligan's");
      expect(getByPlaceholderText('Description')).toHaveValue('Steak and bacon');
    });
  });

  it('saves the organisation name and description', async () => {
    mockAxios.onPut()
      .reply(200);

    const { getByPlaceholderText, getAllByText } = render(
      <Edit
        organisation={organisationStub}
        route={editRoute}
        users={usersStub}
      />
    );

    await user.type(getByPlaceholderText('Name'), ' Steakhouse');
    await user.type(getByPlaceholderText('Description'), '. We heard you, Ron');

    await user.click(getAllByText('Save')[0]);

    expect(
      mockAxios.request('put', '/api/admin/organisations/c90933f6-7e16-4459-aafd-a717238da769')
        .dataObject,
    ).toStrictEqual({
      name: "Mulligan's Steakhouse",
      description: 'Steak and bacon. We heard you, Ron',
    });
  });

  it('shows active toggle if the user has permission', async () => {
    window.Claims = ['login:manage-organisations'];

    const { getByText } = render(
      <Edit
        organisation={organisationStub}
        route={editRoute}
        users={usersStub}
      />
    );

    await waitFor(() => {
      expect(getByText('Active')).toBeInTheDocument();
    });
  });

  it('shows the active toggle if the user does not have permission', async () => {
    const { queryByText } = render(
      <Edit
        organisation={organisationStub}
        route={editRoute}
        users={usersStub}
      />
    );

    await waitFor(() => {
      expect(queryByText('Active')).not.toBeInTheDocument();
    });
  });

  it('lists organisation users', async () => {
    const { getByText } = render(
      <Edit
        organisation={organisationStub}
        route={editRoute}
        users={usersStub}
      />
    );

    const userEditUrl = `/admin/users/${usersStub.data[0].uuid}/edit`;

    await waitFor(() => {
      expect(getByText('Wod')).toBeInTheDocument();
      expect(getByText('nunya@biz.ness')).toBeInTheDocument();
      expect(getByText('Inactive')).toBeInTheDocument();
      expect(getByText('Service Account')).toBeInTheDocument();
      expect(getByText('Eater, Sitter')).toBeInTheDocument();

      expect(getByText('Wod').parentElement).toHaveAttribute('href', userEditUrl);
    });
  });

  it('paginates organisation users', async () => {
    const { getByLabelText } = render(
      <Edit
        organisation={organisationStub}
        route={editRoute}
        users={usersStub}
      />
    );

    await waitFor(() => {
      expect(getByLabelText('Page 1 is your current page')).toBeInTheDocument();
      expect(getByLabelText('Page 2')).toBeInTheDocument();
      expect(getByLabelText('Next page')).toBeInTheDocument();
    });
  });

  it('deactivates the organisation', async () => {
    window.Claims = ['login:manage-organisations'];

    mockAxios.onPut()
      .reply(200);

    const { container } = render(
      <Edit
        organisation={organisationStub}
        route={editRoute}
        users={usersStub}
      />
    );

    await user.click(container.querySelector('[type="checkbox"]'));

    expect(mockAxios.request('put', `/api/admin/organisations/${organisationStub.uuid}/toggle-active`))
      .toBeDefined();
  });
});

const organisationStub = {
  uuid: 'c90933f6-7e16-4459-aafd-a717238da769',
  name: "Mulligan's",
  description: 'Steak and bacon',
};

const editRoute = '/api/admin/organisations/c90933f6-7e16-4459-aafd-a717238da769';

const usersStub = {
  data: [{
    uuid: '5c3f8b54-7334-413b-8500-0a52e6908ac4',
    name: 'Wod',
    email: 'nunya@biz.ness',
    deleted_at: '2023-12-21',
    is_service_account: true,
    roles: [
      'Eater',
      'Sitter',
    ],
  }],
  meta: {
    current_page: 1,
    last_page: 2,
  },
};
