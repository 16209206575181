import React, { useState } from 'react';
import Switch from './Switch';
import { toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import useAjax from './hooks/useAjax';

const ActiveToggle = (props) => {
  const [active, setActive] = useState(props.active);
  const ajax = useAjax();

  const toggleActiveState = (newValue) => {
    ajax.request('put', props.route)
      .then(() => {
        toast(`They have been ${newValue ? 'enabled' : 'disabled'}`, {
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER,
        })

        setActive(newValue);
      })
  }

  return <div className="flex items-center">
    { ajax.loading
      ? <ClipLoader size="1rem" color="#90CDF4"/>
      : active
        ? <span className="rounded-full px-2 text-xs text-green-600 bg-green-200">Active</span>
        : <span className="rounded-full px-2 text-xs text-red-600 bg-red-200">Inactive</span>
    }

    <div className="ml-2 flex items-center">
      <Switch onChange={toggleActiveState}
              value={active}
              height={18}
              width={39}/>
    </div>
  </div>
};

export default ActiveToggle;
