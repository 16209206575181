import React from 'react';
import { render, within } from '@testing-library/react';
import Create from './Create';
import userEvent from '@testing-library/user-event';
import { createMockAxios } from '../../../helpers/MockAxios';
import { router } from '@inertiajs/react';

const mockAxios = createMockAxios();

describe('Pages/Roles/Create', () => {
  let user;

  beforeEach(() => {
    window.Claims = [];

    user = userEvent.setup();

    jest.clearAllMocks();

    mockAxios.resetHistory();

    jest.spyOn(router, 'visit').mockImplementation(() => {});
  });

  it('lists the claims', () => {
    const { getByText } = render(
      <Create
        claims={claimsStub}
      />
    );

    expect(
      within(getByText('companies:edit').parentElement)
        .getByRole('checkbox'),
    ).not.toBeChecked();

    expect(
      within(getByText('login:index').parentElement)
        .getByRole('checkbox'),
    ).not.toBeChecked();
  });

  it('saves changes to the claims', async () => {
    mockAxios.onPost()
      .reply(200);

    const { getByText } = render(
      <Create
        organisation={organisation}
        claims={claimsStub}
      />
    );

    await user.click(getByText('login:index'));
    await user.click(getByText('companies:edit'));

    await user.click(getByText('Create'));

    const request = mockAxios.request(
      'post',
      `/api/admin/organisations/${organisation}/roles`,
    );

    expect(request.data.getAll('claims[]')).toStrictEqual(['companies:edit', 'login:index']);
  });
});

const claimsStub = [
  { id: 1, claim: 'companies:edit' },
  { id: 2, claim: 'login:index' },
];

const organisation = '6051de00-ced5-49fe-bbbd-f8712c481cd5';
