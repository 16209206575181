import React from 'react';
import useClaims from './hooks/useClaims';
import Icon from './Icon';
import classNames from 'classnames';
import { Link as InertiaLink } from '@inertiajs/react';

const NavItems = () => {

    const claims = useClaims();

    const items = {
        'My Account': {
            'icon': 'user',
            'url': '/my-account',
            'show': true
        },
        'Manage Users': {
            'icon': 'users',
            'url': '/admin/users',
            'show': claims.hasAnyClaim(['login:root', 'login:list-users'])
        },
        'Manage Organisations': {
            'icon': 'organisation',
            'url': '/admin/organisations',
            'show': claims.hasAnyClaim(['login:root', 'login:list-users'])
        },
    };

    return <ul className="py-12">
        {Object.keys(items).filter(page => items[page].show).map((page, index) => (
            <li key={index} className={classNames({
                'border-l-4 hover:border-blue-200 hover:bg-blue-700': true,
                'border-transparent': !location.pathname.includes(items[page].url),
                'border-blue-200': location.pathname.includes(items[page].url)
            })}>
                <InertiaLink href={items[page].url}>
                    <div className="px-10 py-3 font-light text-lg">
                        <div className="-ml-1 flex items-center hover:text-blue-200">
                            <Icon name={items[page].icon} className="h-4 w-4"/>
                            <span className="ml-2">{ page }</span>
                        </div>
                    </div>
                </InertiaLink>
            </li>
        ))}
    </ul>
};

export default NavItems;
