import React, {useState, useEffect} from 'react';
import {Button} from '../../Buttons';
import Claim from './Claim';
import useAjax from '../../hooks/useAjax';
import {toast} from 'react-toastify';
import GroupClaims from './GroupClaims';

const Claims = ({claims, user}) => {

  const ajax = useAjax(`/api/admin/users/${user.uuid}`);

  const [readOnlyClaims, setReadOnlyClaims] = useState(user.roles.flatMap(role => role.claims).map(claim => claim.id));

  useEffect(() => {
    setReadOnlyClaims(user.roles.flatMap(role => role.claims).map(claim => claim.id));
  }, [user.roles]);

  const submitForm = async e => {
    e.preventDefault();

    const response = await ajax.save(new FormData(e.target))
      .catch(err => console.error(err));

    if (response?.status !== 200) {
      return;
    }

    toast('Claims updated!', {
      type: toast.TYPE.SUCCESS,
      position: toast.POSITION.TOP_CENTER,
    });
  };

  return <>
    <form onSubmit={submitForm}>
      <input type="hidden" name="_method" value="PUT"/>

      <GroupClaims>
        {claims.map((claim, index) => (
          <Claim key={index}
                 claim={claim.claim}
                 defaultChecked={user.claims.some(({id}) => id === claim.id) || readOnlyClaims.includes(claim.id)}
                 disabled={readOnlyClaims.includes(claim.id)}
          />
        ))}
      </GroupClaims>

      <div className="flex justify-end">
        <Button className="w-20 justify-center" loading={ajax.loading}>Save</Button>
      </div>
    </form>
  </>;
};

export default Claims;