import React from 'react';
import { render } from '@testing-library/react';
import Create from './Create';
import userEvent from '@testing-library/user-event';
import { createMockAxios } from '../../../helpers/MockAxios';
import { router } from '@inertiajs/react';

const mockAxios = createMockAxios();

describe('Pages/Organisations/Create', () => {
  let user;
  let routerSpy;

  beforeEach(() => {
    window.Claims = [];

    user = userEvent.setup();

    jest.clearAllMocks();

    mockAxios.resetHistory();

    routerSpy = jest.spyOn(router, 'visit').mockImplementation(() => {});
  });

  it('saves the organisation name and description', async () => {
    mockAxios.onPost()
      .reply(200, {
        data: {
          uuid: newOrganisationUuid,
        }
      });

    const { getByPlaceholderText, getByText } = render(
      <Create
        route={storeRoute}
      />
    );

    await user.type(getByPlaceholderText('Name'), "Mulligan's");
    await user.type(getByPlaceholderText('Description'), 'Steak and bacon');

    await user.click(getByText('Save'));

    expect(
      mockAxios.request('post', '/api/admin/organisations')
        .dataObject,
    ).toStrictEqual({
      name: "Mulligan's",
      description: 'Steak and bacon',
    });

    expect(routerSpy).toHaveBeenCalledWith(`/admin/organisations/${newOrganisationUuid}/edit`);
  });
});

const storeRoute = '/api/admin/organisations';

const newOrganisationUuid = '893e35fd-0af6-4b9b-8358-b2f82e11dc0b';
