import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from '../Buttons';
import BeatLoader from 'react-spinners/BeatLoader';

const OrganisationForm = ({organisation, onSubmit, loading, errors}) => {
  const [data, setData] = useState({
    name: organisation?.name || '',
    description: organisation?.description || '',
  });

  const updateData = (property) => {
    return (e) => setData({
      ...data,
      [property]: e.target.value,
    });
  }

  return <>
    <div className=" mb-4">
      <div className="flex items-center">
        <label className="inline-block w-24 text-xs font-light text-gray-600 uppercase" htmlFor="name">Name</label>
        <input type="text"
               placeholder="Name"
               name="name"
               defaultValue={data.name}
               onChange={updateData('name')}
               className={classNames({
                 'block border focus:outline-none rounded w-64 px-2 py-1': true,
                 'border-gray-300 focus:border-blue-500': !errors.name,
                 'border-red-500 text-red-500': !!errors.name,
               })}
        />
      </div>
      {!!errors.name &&
        <p className="ml-24 text-xs font-light italic text-red-500 font-light">{errors.name[0]}</p>
      }
    </div>
    <div className="mb-4">
      <div className="flex items-center">
        <label className="inline-block w-24 text-xs font-light text-gray-600 uppercase" htmlFor="email">Description</label>
        <textarea placeholder="Description"
                  name="description"
                  defaultValue={data.description}
                  onChange={updateData('description')}
                  className={classNames({
                    'block border focus:outline-none rounded w-64 px-2 py-1': true,
                    'border-gray-300 focus:border-blue-500': !errors.description,
                    'border-red-500 text-red-500': !!errors.description,
                  })} />
      </div>
      {!!errors.description &&
        <p className="ml-24 text-xs font-light italic text-red-500 font-light">{errors.description[0]}</p>
      }
    </div>

    <div className="flex justify-end">
      <Button onClick={() => onSubmit(data)} className="w-20 justify-center">
        { loading
          ? <BeatLoader color="#EBF8FF" size={7}/>
          : <span>Save</span>
        }
      </Button>
    </div>
  </>
};

export default OrganisationForm;
