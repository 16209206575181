import React from 'react';
import { render, screen } from '@testing-library/react';
import AdditionalScopesRequired from './AdditionalScopesRequired';
import userEvent from '@testing-library/user-event';

describe('Seeker/AdditionalScopesRequired', () => {
  it('renders', () => {
    render(<AdditionalScopesRequired />);
  });

  it('opens a window to reauthenticate when the button is clicked', async () => {
    render(<AdditionalScopesRequired />);

    global.open = jest.fn();
    global.open.mockReturnValue({
      focus: jest.fn()
    });

    await userEvent.click(screen.getByRole('button', {  name: /re\-authenticate with facebook/i}));

    expect(global.open).toHaveBeenCalledTimes(1);
    const [socialUrl] = global.open.mock.calls[0];
    expect(socialUrl).toEqual('/login/seeker/facebook/re-request');
  });
});