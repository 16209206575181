import MockAdapter from 'axios-mock-adapter';
import axios from 'axios';

export class MockAxios {
  constructor(mockAdapter) {
    this._mockAdapter = mockAdapter;
  }

  onGet(url) {
    return this._mockAdapter.onGet(url || /.*/);
  }

  onPost(url) {
    return this._mockAdapter.onPost(url || /.*/);
  }

  onPut(url) {
    return this._mockAdapter.onPut(url || /.*/);
  }

  onDelete(url) {
    return this._mockAdapter.onDelete(url || /.*/);
  }

  resetHistory() {
    this._mockAdapter.resetHistory();
  }

  request(method, url = '') {
    return this.requests(method, url).shift();
  }

  requests(method, url = '') {
    return this._mockAdapter
      .history[method]
      .filter((request) => request.url.match(
        typeof url === 'string'
          ? url.replace(/\?/, '\\?')
          : url
      ))
      .map((request) => {
        return { ...request, dataObject: this._parseJson(request.data) };
      });
  }

  get history() {
    return this._mockAdapter.history;
  }

  get adapter() {
    return this._mockAdapter;
  }

  _parseJson(json) {
    try {
      return JSON.parse(json);
    } catch (error) {
      return null;
    }
  }
}

export const createMockAxios = () => new MockAxios(new MockAdapter(axios));
