import React from "react";
import classNames from "classnames";

const Card = React.forwardRef(({ subtle, withMargin, children }, ref) => {
  return (
    <div className="bg-white overflow-hidden shadow-md rounded-sm" ref={ref}>
      <div className="px-4 py-5 sm:p-6">
        <div className="flex flex-col justify-center">
          {children}
        </div>
      </div>
    </div>
  );
});

export default Card;
