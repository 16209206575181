import React from 'react';
import ReactPaginate from 'react-paginate';
import { router as Inertia } from '@inertiajs/react';

const Paginator = ({pagination, queryParameterForPage = 'page'}) => {

    const visitNewPage = page => {
        let search = new URLSearchParams(location.search)
        search.set(queryParameterForPage, page.selected + 1);
        Inertia.visit(`${location.origin}${location.pathname}?${search.toString()}`);
    };

    return <ReactPaginate pageCount={pagination.last_page}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          forcePage={(pagination.current_page || 1) - 1}
                          disableInitialCallback={true}
                          activeLinkClassName="underline"
                          containerClassName="inline-flex items-center bg-white rounded-lg shadow-md"
                          pageClassName="px-4 my-2 border-r"
                          previousClassName="px-4 my-2 border-r"
                          breakClassName="px-4 my-2 border-r"
                          nextClassName="px-4 my-2"
                          pageLinkClassName="text-blue-600 hover:text-blue-300 focus:outline-none focus:text-blue-300"
                          previousLinkClassName="text-blue-600 hover:text-blue-300 focus:outline-none focus:text-blue-300"
                          breakLinkClassName="text-blue-600 hover:text-blue-300 focus:outline-none focus:text-blue-300"
                          nextLinkClassName="text-blue-600 hover:text-blue-300 focus:outline-none focus:text-blue-300"
                          disabledClassName="hidden"
                          onPageChange={visitNewPage}
    />
};

export default Paginator;
