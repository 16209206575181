import React from 'react';

const Input = ({id, placeholder, error, ...extraProps}) => (<>
  <input
    className="w-full rounded p-3 font-light border placeholder-gray-600 placeholder-opacity-50 focus:outline-none focus:shadow-lg focus:ring-1 focus:border-blue"
    id={id}
    name={id}
    placeholder={placeholder}
    {...extraProps}
  />
  {!!error &&
    <p className="mt-2 text-sm text-red-600" id={`${id}-error`}>{error}</p>
  }
</>);

export default Input;