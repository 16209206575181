import React from 'react';
import Table, {Cell} from '../../Table';
import Card from '../../Card';
import classNames from 'classnames';
import usePagination from '../../hooks/usePagination';
import Paginator from '../../Paginator';
import Icon from '../../Icon';
import {Anchor} from '../../Buttons';

const Roles = ({organisation}) => {

    const pagination = usePagination(`/api/admin/organisations/${organisation}/roles`);

    const paginationIsVisible = () => pagination.pagination.last_page !== undefined && pagination.pagination.last_page !== 1;

    return <Card>
        {!!pagination.data.length
            ? <Table headers={['Roles']}>
                <colgroup>
                    <col width="100%"/>
                    <col width="0%"/>
                </colgroup>
                <tbody className="bg-white divide-y divide-gray-200">
                {pagination.data.map((role, index) => (
                    <tr className="hover:bg-gray-100 focus-within:bg-gray-100"
                        key={index}>
                        <Cell primary
                              href={`/admin/organisations/${organisation}/roles/${role.uuid}/edit`}>
                            {role.name}
                        </Cell>
                        <Cell href={`/admin/organisations/${organisation}/roles/${role.uuid}/edit`}>
                            <Icon name="arrowRight"
                                  className="h-4 w-4"/>
                        </Cell>
                    </tr>
                ))}
                </tbody>
            </Table>
            : <div className="flex justify-center border border-gray-300 py-4 text-gray-500">
                <p>There are no roles for this organisation</p>
            </div>
        }

        <div className={classNames('mt-4 flex', {
            'justify-end': !paginationIsVisible(),
            'justify-between': paginationIsVisible()
        })}>
            {paginationIsVisible() && <>
                <Paginator pagination={pagination.pagination} change={(page) => pagination.setPage(page.selected + 1)}/>
            </>}
            <Anchor href={`/admin/organisations/${organisation}/roles/create`}>New Role</Anchor>
        </div>


    </Card>;
};

export default Roles;
