import React, { useState } from 'react';
import usePopupCenter from './usePopupCenter';
import socialDimensions from '../socialDimensions';

const useOauth = (application) => {
  const [oauthWindow, setOauthWindow] = useState(null);

  const initiateOauth = (provider) => {
    setOauthWindow(usePopupCenter({
      url: `/login/${application}/${provider}`,
      title: 'Login with social',
      height: socialDimensions[provider].height,
      width: socialDimensions[provider].width,
    }));
  };

  const closeOauthWindow = () => {
    if (oauthWindow) {
      oauthWindow.close();
      setOauthWindow(null);
    }
  };

  const reRequestScopes = () => {
    if (window.opener) {
      window.resizeTo(980, 600);
      return window.location.href = `/login/${application}/facebook/re-request`;
    }

    usePopupCenter({
      url: `/login/${application}/facebook/re-request`,
      title: 'OAuth Window',
      height: 600,
      width: 980
    })
  };

  return {
    initiateOauth,
    closeOauthWindow,
    reRequestScopes,
  }
};

export default useOauth;