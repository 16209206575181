import React from 'react';

const usePostMessage = (data) => {
  if (window.parent) {
    window.parent.postMessage(data, '*');
  }

  if (window.opener) {
    window.opener.postMessage(data, '*');
  }

  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  }
};

export default usePostMessage;