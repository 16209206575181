import React, {useState, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';

const Claim = ({claim, defaultChecked, disabled}) => {
  const [checked, setChecked] = useState(defaultChecked);

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked])

  return <label className="inline-flex items-center"
                data-tip={disabled
                  ? 'This claim is being granted by a role the user holds'
                  : ''
                }>
    <input type="checkbox"
           name="claims[]"
           value={claim}
           checked={checked}
           onChange={e => setChecked(e.target.checked)}
           disabled={disabled}/>
    <span className="ml-2 text-sm">{claim}</span>

    { disabled && <ReactTooltip effect="solid" html={true}/>}
  </label>
};

export default Claim;