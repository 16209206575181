import React, { Fragment } from 'react';
import useAjax from '../../hooks/useAjax';
import Layout from '../../Layout';
import { toast, ToastContainer } from 'react-toastify';
import Card from '../../Card';
import { router as Inertia } from '@inertiajs/react';
import { Button } from '../../Buttons';
import Input from '../../Input';
import useGroupedClaims from '../../hooks/useGroupedClaims';
import { Link as InertiaLink } from '@inertiajs/react';
import Icon from '../../Icon';

const Edit = ({organisation, claims, role}) => {

    const groupedClaims = useGroupedClaims(claims);

    const ajax = useAjax(`/api/admin/organisations/${organisation}/roles/${role.data.uuid}`);
    const deleteAjax = useAjax();

    const submitForm = async e => {
        e.preventDefault();

        const response = await ajax.save(new FormData(e.target))
            .catch(err => console.error(err));

        if (response?.status !== 200) {
            return;
        }

        toast('Role updated!', {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.TOP_CENTER,
        });
    };

    const deleteRole = async () => {
        const response = await deleteAjax.request(
            'delete',
            `/api/admin/organisations/${organisation}/roles/${role.data.uuid}`
        ).catch(err => console.error(err));

        if (response?.status !== 204) {
           return;
        }

        toast('Role deleted!', {
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.TOP_CENTER,
        });

        Inertia.visit(`/admin/organisations/${organisation}/edit`);
    };

    return <Layout>
        <InertiaLink href={`/admin/organisations/${organisation}/edit`} className="flex items-center mb-4 text-blue-500 hover:text-blue-600">
            <Icon name="arrowLeft" className="h-4"/>
            <span className="ml-1">Back to organisation</span>
        </InertiaLink>

        <div className="max-w-5xl">
            <div className="flex items-center justify-between mb-6">
                <h2 className="text-3xl tracking-wide">Edit a new role</h2>
                <Button type="danger" onClick={deleteRole} loading={deleteAjax.loading}>Delete Role</Button>
            </div>

            <Card>
                <form onSubmit={submitForm}>
                    <input type="hidden" name="_method" value="PUT" />
                    <div className="mb-6">
                        <Input id="name"
                               label="Name"
                               defaultValue={role.data.name}
                               error={ajax.errors.name}/>
                    </div>

                    { Object.keys(groupedClaims).map(group => (<Fragment key={group}>
                        <h3 className="mb-2 text-xs font-light text-gray-600 uppercase">{group}</h3>
                        <div className="flex flex-wrap -m-1 mb-4">
                            { groupedClaims[group].map(claim => (
                                <div className="flex items-center p-1 w-1/2 xl:w-1/3 has-[:checked]:bg-blue-200/20" key={claim.id}>
                                    <label className="inline-flex items-center">
                                        <input type="checkbox"
                                               name="claims[]"
                                               value={claim.claim}
                                               defaultChecked={role.data.claims.includes(claim.claim)}/>
                                        <span className="ml-2 text-sm">{claim.claim}</span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </Fragment>))}

                    <div className="mt-8 flex justify-end">
                        <Button loading={ajax.loading}>Update</Button>
                    </div>
                </form>
            </Card>
        </div>
        <ToastContainer/>
    </Layout>;
};

export default Edit;
