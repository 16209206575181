import React, { useState } from 'react';
import UserForm from "../Forms/UserForm";
import { ToastContainer, toast } from 'react-toastify';
import useAjax from "../hooks/useAjax";
import Layout from '../Layout';

const MyAccount = ({user, route}) => {

    const [showChangePassword, setShowChangePassword] = useState(false);
    const ajax = useAjax(route);

    const onSubmit = data => {
        Object.keys(data).forEach((key) => (key.includes('password') && data[key] == "") && delete data[key]);

        ajax.request('put', route, data)
            .then(() => {
                toast('Account updated!', {
                    type: toast.TYPE.SUCCESS,
                    position: toast.POSITION.TOP_CENTER,
                });
                setShowChangePassword(false);
            })
    };

    return <Layout>
        <div className="text-sm">
            <h2 className="text-2xl tracking-wide mb-10">{user.name}</h2>

            <div className="flex flex-wrap">
                <div className="mb-8 w-full max-w-lg mr-6">
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <div className="flex items-center justify-between mb-6">
                            <h3 className="text-xl tracking-wide">Edit account</h3>
                        </div>
                        <UserForm user={user}
                                  onSubmit={onSubmit}
                                  loading={ajax.loading}
                                  errors={ajax.errors}
                                  showChangePassword={showChangePassword}
                                  setShowChangePassword={setShowChangePassword}
                                  showOrganisations={false}/>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    </Layout>;
};

export default MyAccount;
