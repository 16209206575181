import React, { useEffect, useLayoutEffect } from 'react';
import Button from './Button';
import Input from './Input';
import useAjax from '../../hooks/useAjax';
import Card from './Card';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../../tailwind.config';
import BeatLoader from 'react-spinners/BeatLoader';
import { router as Inertia } from '@inertiajs/react';
import Tracked from '../../Tracked';
import usePostMessage from '../../hooks/usePostMessage';
import useOauth from '../../hooks/useOauth';

const { theme } = resolveConfig(tailwindConfig);

const Login = ({ isAuthenticated }) => {

  const ajax = useAjax('/login');
  const {initiateOauth, closeOauthWindow} = useOauth('seeker');

  useLayoutEffect(() => {
    usePostMessage({ resize: document.body.scrollHeight });
  }, []);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin.includes('login')) {
        handleRedirect(e.data.redirect);
      }
    });
  }, []);

  const login = async (e) => {
    e.preventDefault();

    closeOauthWindow();

    const response = await ajax
      .save(new FormData(e.target))
      .catch((err) => console.error(err));
  
    if (response?.status !== 204) {
      return usePostMessage({ resize: document.body.scrollHeight });
    }

    usePostMessage('User authenticated');
  };
  const handleRedirect = redirect => {
    if (!redirect) {
      return Inertia.reload();
    }

    Inertia.visit(redirect);
  }

  return (
    <Card>
      {isAuthenticated
        ? <AlreadyLoggedIn/>
        : <>
          <form onSubmit={login} className="px-1">
           <div className="mb-6">
              <Input
                id="email"
                label="Email"
                type="email"
                placeholder="Your email"
                required
                error={ajax.errors.email}
              />
            </div>

             <div className="mb-6">
              <Input
                id="password"
                label="Password"
                type="password"
                placeholder="Your password"
                error={ajax.errors.password}
              />
            </div>

            <div className="flex flex-col">
              <Tracked label="login-with-email-and-password">
                <Button loading={ajax.loading} variant="primary">Login</Button>
              </Tracked>
            </div>
          </form>

          <div className="flex justify-center items-center w-full my-8">
            <hr className="border-t border-gray-600 flex-1"/>
            <p className="text-gray-600 px-3">OR</p>
            <hr className="border-t border-gray-600 flex-1"/>
          </div>

          <div className="w-full">
            <Tracked label="login-with-google">
              <button onClick={() => initiateOauth('google')}
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-400 rounded-full bg-white text-sm leading-5 font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out">
                <svg className="w-5 h-5" viewBox="0 0 488 512" fill="currentColor">
                  <path
                    d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"/>
                </svg>
                <span className="ml-3 mr-1">Continue with</span>
                <span className="text-blue-500">G</span>
                <span className="text-red-500">o</span>
                <span className="text-brand-orange">o</span>
                <span className="text-blue-500">g</span>
                <span className="text-green-500">l</span>
                <span className="text-red-500">e</span>
              </button>
            </Tracked>
          </div>

          <div className="w-full mt-6">
            <Tracked label="login-with-facebook">
              <button onClick={() => initiateOauth('facebook')}
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-400 rounded-full bg-white text-sm leading-5 font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out">
                <svg className="w-5 h-5" viewBox="0 0 320 512" fill="currentColor">
                  <path fill="currentColor"
                        d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"/>
                </svg>
                <span className="ml-3 mr-1">Continue with</span>
                <span className="text-blue-600">Facebook</span>
              </button>
            </Tracked>
          </div>

          <div className="w-full mt-6">
            <Tracked label="login-with-apple">
              <button onClick={() => initiateOauth('apple')}
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-400 rounded-full bg-white text-sm leading-5 font-medium text-gray-700 hover:bg-gray-100 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition duration-150 ease-in-out">
                <svg className="w-5 h-5" fill="currentColor">
                  <path fill="currentColor"
                        d="M15.88 10.57c0-1.647.9-3.085 2.236-3.847-.697-.856-1.747-1.623-2.904-1.848-1.578-.308-2.478.204-3.06.41-.582.204-1.355.389-1.355.389s-.773-.185-1.356-.39c-.581-.205-1.481-.718-3.06-.41-1.577.307-2.955 1.623-3.515 2.797-1.08 2.264-.635 5.113.128 7.162.762 2.05 2.777 4.913 4.34 5.144 1.112.163 1.8-.605 3.463-.8 1.662.195 2.35.963 3.462.8 1.563-.23 3.578-3.094 4.34-5.144l.057-.154a4.426 4.426 0 01-2.776-4.108zM10.402 4.5s1.546.205 2.95-1.39C14.754 1.518 14.355.01 14.355.01s-1.546-.206-2.95 1.388C10.002 2.992 10.402 4.5 10.402 4.5z"/>
                </svg>
                <span className="ml-3 mr-1 text-gray-700">Continue with</span>
                <span className="text-gray-900">Apple</span>
              </button>
            </Tracked>
          </div>

          <a className="inline-flex justify-center mt-6 w-full text-brand-blue" href="https://seeker.iseekplant.com.au/password/reset">
            Forgot your password?
          </a>
        </>
      }
    </Card>
  );
};

const AlreadyLoggedIn = () => {
  useEffect(() => {
    usePostMessage('User authenticated');
    if (window.opener) {
      window.close();
    }
  }, []);

  return <div className="flex flex-col items-center py-20">
    <p className="mb-4 text-gray-700">You are logged in</p>
    <p className="mb-4 text-gray-700">Redirecting you now</p>
    <BeatLoader color={theme.colors.blue['500']}/>
  </div>;
};

export default Login;
