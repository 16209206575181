import React from 'react';
import { render, within } from '@testing-library/react';
import Edit from './Edit';
import userEvent from '@testing-library/user-event';
import { createMockAxios } from '../../../helpers/MockAxios';
import { router } from '@inertiajs/react';

const mockAxios = createMockAxios();

describe('Pages/Roles/Edit', () => {
  let user;

  beforeEach(() => {
    window.Claims = [];

    user = userEvent.setup();

    jest.clearAllMocks();

    mockAxios.resetHistory();

    jest.spyOn(router, 'visit').mockImplementation(() => {});
  });

  it('lists the claims', () => {
    const { getByText } = render(
      <Edit
        claims={claimsStub}
        role={roleStub}
      />
    );

    expect(
      within(getByText('companies:edit').parentElement)
        .getByRole('checkbox'),
    ).not.toBeChecked();

    expect(
      within(getByText('login:index').parentElement)
        .getByRole('checkbox'),
    ).toBeChecked();
  });

  it('saves changes to the claims', async () => {
    mockAxios.onPost()
      .reply(200);

    const { getByText } = render(
      <Edit
        organisation={organisation}
        claims={claimsStub}
        role={roleStub}
      />
    );

    await user.click(getByText('companies:edit'));

    await user.click(getByText('Update'));

    const request = mockAxios.request(
      'post',
      `/api/admin/organisations/${organisation}/roles/${roleStub.data.uuid}`,
    );

    expect(request.data.getAll('claims[]')).toStrictEqual(['companies:edit', 'login:index']);
  });

  it('deletes the role', async () => {
    mockAxios.onDelete()
      .reply(200);

    const { getByText } = render(
      <Edit
        organisation={organisation}
        claims={claimsStub}
        role={roleStub}
      />
    );

    await user.click(getByText('Delete Role'));

    expect(
      mockAxios.request(
        'delete',
        `/api/admin/organisations/${organisation}/roles/${roleStub.data.uuid}`,
      ),
    ).toBeDefined();
  });
});

const claimsStub = [
  { id: 1, claim: 'companies:edit' },
  { id: 2, claim: 'login:index' },
];

const roleStub = {
  data: {
    uuid: '893e35fd-0af6-4b9b-8358-b2f82e11dc0b',
    claims: [
      'login:index',
    ],
  },
};

const organisation = '6051de00-ced5-49fe-bbbd-f8712c481cd5';
