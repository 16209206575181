import React from 'react';
import Icon from './Icon';
import classNames from 'classnames';

const Table = ({headers, children}) => (
    <div className="flex flex-col">
        <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
            <div className="align-middle inline-block min-w-full overflow-hidden rounded-lg">
                <table className="min-w-full">
                    <thead className="border-b border-gray-200">
                        <tr>
                            {headers.map((header, index) => (
                                <Header key={index}>{header}</Header>
                            ))}
                            <th className="px-6 py-3"></th>
                        </tr>
                    </thead>
                    {children}
                </table>
            </div>
        </div>
    </div>
);


const Header = ({children}) => (
    <th className="px-6 py-4 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
        {children}
    </th>
);

export const Cell = ({children, primary, href}) => {
    const classes = classNames('px-6 py-4 whitespace-nowrap text-sm leading-5 flex items-center focus:outline-none', {
        'text-gray-500': primary === undefined,
        'text-gray-900 font-medium': primary !== undefined,
    });

    if (!!href) {
        return <td>
            <a href={href}
               className={classes}
               tabIndex={primary === undefined ? '-1' : '1'}>
                {children}
            </a>
        </td>
    }

    return <td className={classes}>
        {children}
    </td>
};

export default Table;
