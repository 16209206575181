export default {
  google: {
    height: 600,
    width: 450
  },
  facebook: {
    height: 600,
    width: 980
  },
  apple: {
    height: 675,
    width: 660,
  },
};
