const defaultTheme = require('tailwindcss/defaultTheme');

module.exports = {
  content: [
    './resources/**/*.blade.php',
    './resources/**/*.scss',
    './resources/**/*.js',
  ],
  safelist: [
    /^Toastify/,
    /^Tooltip/,
  ],
  theme: {
    extend: {
      fontFamily: {
        sans: ['Sora', ...defaultTheme.fontFamily.sans],
      },
      boxShadow: {
        xs: '0 0 0 1px rgba(0, 0, 0, 0.05)',
        outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
      },
      colors: {
        'brand-blue': '#000F87',
        'brand-blue-light': '#FFFFFF',
        'brand-orange': '#FF612D',
        'brand-orange-light': '#FFECDA',
        'brand-purple': '#8A60C7',
        'brand-green': '#378093',
        'brand-gray': '#000A4680',
        'brand-pink': '#C13777',
        'knowledge-blue': '#266EF6',
        'blue': {
          "50": "#fafaff",
          "100": "#fafaff",
          "200": "#d6dbff",
          "300": "#adb7ff",
          "400": "#7081ff",
          "500": "#000f85",
          "600": "#000f85",
          "700": "#000f85",
          "800": "#000f85",
          "900": "#000b5c"
        },
        'orange': {
          50: '#FFF8F1',
          100: '#FEF5EC',
          200: '#FDE6CF',
          300: '#FBD6B2',
          400: '#F9B879',
          500: '#F6993F',
          600: '#DD8A39',
          700: '#945C26',
          800: '#6F451C',
          900: '#4A2E13',
        },
        current: 'currentColor',
      },
    },
  },
}
