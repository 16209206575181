import React from 'react';
import { render, within } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { createMockAxios } from '../../helpers/MockAxios';
import { router } from '@inertiajs/react';
import Dashboard from './Dashboard';

const mockAxios = createMockAxios();

describe('Pages/Dashboard', () => {
  it('Shows user name on Dashboard', () => {
    window.User = {
      name: 'The Name'
    };

    window.Claims = [];

    const { getByText } = render(
      <Dashboard
      />
    );

    expect(getByText('The Name')).toBeInTheDocument();
  });
});
