import React, { Children } from 'react';
import { Link as InertiaLink } from '@inertiajs/react'
import BeatLoader from 'react-spinners/BeatLoader';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';

const { theme } = resolveConfig(tailwindConfig);

const classes = {
    primary: 'flex items-center font-semibold tracking-wide text-sm border border-blue-500 bg-blue-500 text-blue-100 py-2 px-4 rounded hover:bg-blue-700 hover:border-blue-700 focus:outline-none',
    secondary: 'flex items-center font-semibold tracking-wide text-sm border border-blue-500 text-blue-500 py-2 px-4 rounded hover:bg-blue-100 hover:border-blue-600 hover:text-blue-600 focus:outline-none',
    danger: 'flex items-center font-semibold tracking-wide text-sm border border-red-700 text-red-700 py-2 px-4 rounded hover:bg-red-100 hover:border-red-600 hover:text-red-600 focus:outline-none'
};

const loaderColours = {
    primary: theme.colors.blue['100'],
    secondary: theme.colors.blue['500'],
    danger: theme.colors.red['700'],
};

const renderChildren = (children) => Children.map(children, child => child);

const Anchor = ({children, href, type}) => {
    return <InertiaLink href={href}
       className={classes[type]}>
        {renderChildren(children)}
    </InertiaLink>
};
const Button = ({children, type, onClick, className, loading}) => {
    const classNames = [classes[type], className].join(' ');

    const handleClick = () => {
        if (!loading) {
            onClick();
        }
    }

    return <button className={classNames} onClick={handleClick}>
        {loading
            ? <div className="flex items-center py-1">
                <BeatLoader
                    color={loaderColours[type]}
                    size={9}
                    css={{display: 'flex'}}
                />
            </div>
            : renderChildren(children)
        }
    </button>
};

Anchor.defaultProps = {
    type: 'primary',
};
Button.defaultProps = {
    type: 'primary',
    onClick: () => {}
};

export { Anchor, Button };
