import React, { useLayoutEffect, useState } from 'react';
import Card from './Card';
import Input from './Input';
import Button from './Button';
import { router as Inertia } from '@inertiajs/react';
import usePostMessage from '../../hooks/usePostMessage';

const ConfirmSocialLink = ({provider, errors}) => {
  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    resize();
  }, []);

  const linkAccount = async e => {
    e.preventDefault();

    setLoading(true);

    await Inertia.post(location.href, new FormData(e.target));

    resize();

    setLoading(false);
  };

  const resize = () => {
    usePostMessage({ resize: document.body.scrollHeight });

    if (window.opener) {
      window.resizeTo(window.outerWidth, document.body.scrollHeight + (window.outerHeight - window.innerHeight));
    }
  }

  const cancel = () => {
    usePostMessage('cancel');

    window.location.href = '/login/account';
  };

  return (
    <Card>
      <div className="text-gray-700 mb-6 text-center text-sm">
        <p className="mb-2 text-xl">Nearly there!</p>
        <p className="mb-1 leading-snug text-gray-600">
          It looks like this is the first time you've logged in with your { provider } account.
        </p>
        <p className="leading-snug text-gray-600">
          Just to make sure it's you, please type your account password in below and we'll link this up to your
          existing account. You won't need to do this again.
        </p>
      </div>
      <form onSubmit={linkAccount} className="px-1">
        <div className="mb-6">
          <Input
            id="password"
            label="iSeekplant Password"
            type="password"
            error={errors.password}
          />
        </div>

        <div className="flex flex-col">
          <Button loading={loading}>Link my {provider} account</Button>
        </div>
        <div className="mt-4 flex flex-col">
          <Button variant="outlined"
                  loading={loading}
                  type="button"
                  onClick={cancel}>Cancel</Button>
        </div>
      </form>
    </Card>
  );
};

export default ConfirmSocialLink;