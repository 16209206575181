const useClaims = () => {
  const hasClaim = (claim) => window.Claims.includes(claim);

  const hasAnyClaim = (claims) => !!claims.filter((claim) => hasClaim(claim)).length;

  return {
    hasClaim,
    hasAnyClaim,
  }
}

export default useClaims;
