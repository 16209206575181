import React, { useEffect, useLayoutEffect } from 'react';
import Card from './Card';
import Button from './Button';
import { router as Inertia } from '@inertiajs/react';
import usePostMessage from '../../hooks/usePostMessage';
import usePopupCenter from '../../hooks/usePopupCenter';

const ConfirmSocialLink = () => {

  useLayoutEffect(() => {
    resize();
  }, []);

  useEffect(() => {
    window.addEventListener('message', (e) => {
      if (e.origin.includes('login')) {
        Inertia.visit(e.data.redirect || '')
      }
    });
  }, []);

  const resize = () => {
    usePostMessage({ resize: document.body.scrollHeight });

    if (window.opener) {
      window.resizeTo(window.outerWidth, document.body.scrollHeight + (window.outerHeight - window.innerHeight));
    }
  };

  const reRequestScopes = () => {
    if (window.opener) {
      window.resizeTo(980, 600);
      return window.location.href = '/login/account/facebook/re-request';
    }

    usePopupCenter({
      url: '/login/account/facebook/re-request',
      title: 'OAuth Window',
      height: 600,
      width: 980
    })
  };

  return (
    <Card>
      <div className="text-gray-700 mb-6 text-center text-sm">
        <p className="mb-2 text-lg text-xl">Uh Oh!</p>
        <p className="mb-1 leading-snug text-gray-600">
          Looks like we didn't quite get all the information we needed from Facebook.
        </p>
        <p className="leading-snug text-gray-600">
          This is usually because you've opted to not share details like your email address. To be able to use
          your Facebook account, we need access to your name and email address to match things up on our side.
        </p>
      </div>
      <Button onClick={reRequestScopes}>Re-authenticate with Facebook</Button>
    </Card>
  );
};

export default ConfirmSocialLink;