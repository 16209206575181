import React, {Fragment, useEffect, useState} from 'react';

const GroupClaims = ({children}) => {
  const [groups, setGroups] = useState({});

  useEffect(() => {
    const newGroups = {};

    React.Children.map(children, child => {
      let key = child.props.claim.split(':')[0]

      newGroups[key] = newGroups[key] || [];

      newGroups[key].push(child);
    });

    setGroups(newGroups)
  }, [children])

  return Object.keys(groups).map(group => (<Fragment key={group}>
      <h3 className="mb-2 text-xs font-light text-brand-blue uppercase">{group}</h3>
      <div className="flex flex-wrap -m-1 mb-8 border-b border-gray-200">
        {groups[group].map((child, index) => (
          <div className="flex p-1 items-center w-1/3 has-[:checked]:bg-blue-200/20" key={index}>
            {React.cloneElement(child)}
          </div>
        ))}
      </div>
  </Fragment>))

};

export default GroupClaims;