import React from 'react';
import { render, screen } from '@testing-library/react';
import Login from './Login';
import userEvent from '@testing-library/user-event';
import mockedAxios from 'axios';
import mockedUsePostMessage from '../../hooks/usePostMessage';

jest.mock('axios', () => ({
  post: jest.fn(),
}));

jest.mock('../../hooks/usePostMessage');

beforeEach(() => {
  window.axios = mockedAxios;
  jest.clearAllMocks();
});

describe('Account/Login', () => {
  it('renders', () => {
    render(<Login />);
  });

  it('allows me to submit my email and password', async () => {
    render(<Login />);

    mockedAxios.post.mockResolvedValue({
      status: 204,
      data: {},
    });

    await userEvent.type(document.getElementById('email'), 'test@mctest.com');
    await userEvent.type(document.getElementById('password'), 'secret');

    await userEvent.click(screen.getByRole('button', { name: /login/i }));

    expect(mockedAxios.post).toHaveBeenCalledTimes(1);
    const[, formData] = mockedAxios.post.mock.calls[0];
    expect(formData.get('email')).toEqual('test@mctest.com');
    expect(formData.get('password')).toEqual('secret');
  });

  it('sends a user authenticated post message when the user successfully signs in', async () => {
    render(<Login />);

    mockedAxios.post.mockResolvedValue({
      status: 204,
      data: {},
    });

    await userEvent.type(document.getElementById('email'), 'test@mctest.com');
    await userEvent.click(screen.getByRole('button', { name: /login/i }));
  
    expect(mockedUsePostMessage).toHaveBeenCalledWith('User authenticated');
  });

  it('displays errors', async () => {
    render(<Login />);

    const spy = jest.spyOn(console, 'error').mockImplementation();

    mockedAxios.post.mockRejectedValueOnce({
      response: {
        status: 422,
        data: {
          errors: {
            email: [
              'There was an error with your email'
            ]
          }
        },
      }
    });

    await userEvent.type(document.getElementById('email'), 'test@mctest.com');
    await userEvent.type(document.getElementById('password'), 'secret');

    await userEvent.click(screen.getByRole('button', { name: /login/i }));

    expect(screen.getByText('There was an error with your email')).toBeInTheDocument();

    spy.mockRestore();
  });

  it('shows a loading screen if i am already logged in', () => {
    render(<Login isAuthenticated={true}/>);

    expect(screen.getByText('You are logged in')).toBeInTheDocument();
    expect(screen.getByText('Redirecting you now')).toBeInTheDocument();
  });

  it('opens a new window when you login with google', async () => {
    render(<Login />);

    global.open = jest.fn();
    global.open.mockReturnValue({
      focus: jest.fn()
    })

    await userEvent.click(screen.getByRole('button', {  name: /continue with g o o g l e/i}));

    expect(global.open).toHaveBeenCalledTimes(1);
    const [socialUrl] = global.open.mock.calls[0];
    expect(socialUrl).toEqual('/login/account/google');
  });

  it('opens a new window when you login with facebook', async () => {
    render(<Login />);

    global.open = jest.fn();
    global.open.mockReturnValue({
      focus: jest.fn()
    })

    await userEvent.click(screen.getByRole('button', {  name: /continue with facebook/i}));

    expect(global.open).toHaveBeenCalledTimes(1);
    const [socialUrl] = global.open.mock.calls[0];
    expect(socialUrl).toEqual('/login/account/facebook');
  });

  it('does not show login with apple button', () => {
    render(<Login />);

    expect(screen.queryAllByText(/continue with apple/i)).toHaveLength(0);
  });

  it('only shows login with apple button when the flag is set to true', () => {
    render(<Login />);

    expect(screen.queryAllByText(/continue with apple/i)).toHaveLength(0);
  });

  it('opens a new window when you login with apple', async () => {

    render(<Login />);

    global.open = jest.fn();
    global.open.mockReturnValue({
      focus: jest.fn()
    })

    await userEvent.click(screen.getByRole('button', { name: /continue with apple/i }));

    expect(global.open).toHaveBeenCalledTimes(1);
    const [socialUrl] = global.open.mock.calls[0];
    expect(socialUrl).toEqual('/login/account/apple');
  });

  it('does not emit a user authenticated message if there is a validation failure', async () => {
    render(<Login />);

    const spy = jest.spyOn(console, 'error').mockImplementation();

    mockedAxios.post.mockRejectedValueOnce({
      response: {
        status: 422,
        data: {
          errors: {
            email: [
              'There was an error with your email'
            ]
          }
        },
      }
    });

    await userEvent.click(screen.getByRole('button', { name: /login/i }));

    expect(mockedUsePostMessage).not.toHaveBeenCalledWith('User authenticated');

    spy.mockRestore();
  });
});
