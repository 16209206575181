import React, {useEffect, useRef} from 'react';

const useGtm = (label) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    ref.current.setAttribute(`data-gtm-${label}`, '');
  }, [ref.current]);

  return ref;
};

export default useGtm;