import React from 'react';
import { Link } from '@inertiajs/react';
import BeatLoader from 'react-spinners/BeatLoader';
import classNames from 'classnames';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../../tailwind.config';

const { theme } = resolveConfig(tailwindConfig);

const getLoaderColor = (color, variant) => {
  const shades = {
    primary: [theme.colors['brand-orange-light'], theme.colors['brand-orange']],
    danger: [theme.colors.red['100'], theme.colors.red['600']],
    boost: [theme.colors.green['100'], theme.colors.green['500']],
  };

  const [light, dark] = shades[color];

  return variant === 'filled' ? light : dark;
};

const classes = (color, variant, disabled, loading) => {
  const primaryClasses = classNames({
    'opacity-50': disabled && !loading,
    ['border-brand-orange bg-brand-orange text-brand-orange-light hover:border-orange-600 hover:bg-orange-600 focus:bg-orange-600 px-4']:
      variant === 'filled',
    ['border-brand-orange px-4']: variant === 'outlined',
    'px-2 border-transparent': variant === 'text',
    ['text-brand-orange hover:bg-brand-orange-light']:
      variant === 'outlined' || variant === 'text',
  });

  const dangerClasses = classNames({
    'opacity-50': disabled && !loading,
    ['border-red-600 bg-red-600 text-red-100 hover:border-red-700 hover:bg-red-700 focus:bg-red-700 px-4']:
      variant === 'filled',
    ['border-red-600 px-4']: variant === 'outlined',
    'px-2 border-transparent': variant === 'text',
    ['text-red-600 hover:bg-red-100']:
      variant === 'outlined' || variant === 'text',
  });

  const boostClasses = classNames({
    'opacity-50': disabled && !loading,
    ['border-green-500 bg-green-500 text-green-100 hover:border-green-600 hover:bg-green-600 focus:bg-green-600 px-4']:
      variant === 'filled',
    ['border-green-500 px-4']: variant === 'outlined',
    'px-2 border-transparent': variant === 'text',
    ['text-green-500 hover:bg-green-100']:
      variant === 'outlined' || variant === 'text',
  });

  return classNames(
    'flex items-center justify-center font-semibold tracking-wide py-2 rounded focus:outline-none border text-sm',
    {
      [primaryClasses]: color === 'primary',
      [dangerClasses]: color === 'danger',
      [boostClasses]: color === 'boost',
    }
  );
};

const ButtonRoot = React.forwardRef(({
  href = null,
  useInertia = false,
  children,
  ...props
}, ref) => {
  if (href) {
    return useInertia ? (
      <Link ref={ref} href={href} {...props}>
        {children}
      </Link>
    ) : (
      <a ref={ref} href={href} {...props}>
        {children}
      </a>
    );
  }

  return (
    <button ref={ref} href={href} {...props}>
      {children}
    </button>
  );
});

const Button = React.forwardRef(
  (
    {
      children,
      color,
      disabled,
      href,
      loading,
      onClick,
      variant,
      ...extraProps
    },
    ref
  ) => (
    <ButtonRoot
      className={classes(color, variant, disabled, loading)}
      onClick={disabled || loading ? null : onClick}
      disabled={disabled || loading}
      href={href}
      ref={ref}
      {...extraProps}
    >
      {loading ? (
        <div className="flex items-center py-1">
          <BeatLoader
            color={getLoaderColor(color, variant)}
            size={9}
            css={{ display: 'flex' }}
          />
        </div>
      ) : (
        children
      )}
    </ButtonRoot>
  )
);

Button.defaultProps = {
  color: 'primary',
  onClick: () => {},
  useInertia: true,
  variant: 'filled',
};

export default Button;
