import React from 'react';
import { render } from '@testing-library/react';
import Edit from './Edit';
import userEvent from '@testing-library/user-event';
import { createMockAxios } from '../../../helpers/MockAxios';
import { router } from '@inertiajs/react';

const mockAxios = createMockAxios();

describe('Pages/Users/Edit', () => {
  let user;

  beforeEach(() => {
    window.Claims = [];

    window.User = {
      uuid: '',
    };

    user = userEvent.setup();

    jest.clearAllMocks();

    jest.spyOn(router, 'reload').mockImplementation(() => {});

    mockAxios.resetHistory();
  });

  it('shows the user name', () => {
    const { getByText, queryByText } = render(
      <Edit
        user={userStub}
      />
    );

    expect(getByText('Ron')).toBeInTheDocument();

    expect(queryByText('Bearer Token')).not.toBeInTheDocument();
  });

  it('prefills the user name and email into the form fields', async () => {
    const { getByPlaceholderText, getByText } = render(
      <Edit
        user={userStub}
      />
    );

    expect(getByPlaceholderText('Name')).toHaveValue('Ron');
    expect(getByPlaceholderText('Email')).toHaveValue('ron@swanson.com.au');

    await user.click(getByText('Change password'));

    expect(getByPlaceholderText('Password')).toHaveValue('');
    expect(getByPlaceholderText('Confirm password')).toHaveValue('');
  });

  it('saves the users name, email and password', async () => {
    mockAxios.onPut()
      .reply(200);

    const { getByPlaceholderText, getAllByText, getByText } = render(
      <Edit
        user={userStub}
        route={editRoute}
      />
    );

    await user.clear(getByPlaceholderText('Name'));
    await user.type(getByPlaceholderText('Name'), 'Ronald');

    await user.click(getByText('Change password'));

    await user.type(getByPlaceholderText('Password'), 'bacon');
    await user.type(getByPlaceholderText('Confirm password'), 'bacon');

    await user.click(getAllByText('Save')[0]);

    expect(
      mockAxios.request('put', '/api/admin/users/c90933f6-7e16-4459-aafd-a717238da769')
        .dataObject,
    ).toStrictEqual({
      name: 'Ronald',
      email: 'ron@swanson.com.au',
      password: 'bacon',
      password_confirmation: 'bacon',
    });
  });

  it('shows service account settings if the user is a service account', () => {
    const { getByText } = render(
      <Edit
        user={{
          ...userStub,
          is_service_account: true,
        }}
        route={editRoute}
      />
    );

    expect(getByText('Bearer Token')).toBeInTheDocument();
  });

  it('shows the edit roles section if the user has permission', () => {
    window.Claims = ['login:manage-roles'];

    const { getByText } = render(
      <Edit
        user={{
          ...userStub,
          is_service_account: true,
        }}
        route={editRoute}
      />
    );

    expect(getByText('Edit Roles')).toBeInTheDocument();
  });

  it('shows the edit claims section if the user has permission', () => {
    window.Claims = ['login:manage-permissions'];

    const { getByText } = render(
      <Edit
        user={{
          ...userStub,
          is_service_account: true,
        }}
        route={editRoute}
        claims={[
          { claim: 'login:do-something' },
        ]}
      />
    );

    expect(getByText('login:do-something')).toBeInTheDocument();
  });

  it('saves the organisations against the user', async () => {
    window.Claims = ['login:manage-organisations'];

    mockAxios.onPut()
      .reply(200);

    mockAxios.onGet(new RegExp('/api/admin/organisations'))
      .reply(200, {
        data: [
          { id: 2, name: 'Instagram' },
        ],
      });

    const { getAllByText, getByText, findByText } = render(
      <Edit
        user={{
          ...userStub,
          organisations: [
            { id: 1, name: 'Twitter' },
          ],
        }}
        route={editRoute}
      />
    );

    await user.click(getByText('Twitter'));

    await user.click(await findByText('Instagram'));

    await user.click(getAllByText('Save')[0]);

    expect(
      mockAxios.request('put', '/api/admin/users/c90933f6-7e16-4459-aafd-a717238da769')
        .dataObject,
    ).toStrictEqual(expect.objectContaining({
      organisations: [1, 2],
    }));
  });

  it('prevents access to edit user organisations without the correct claim', async () => {
    const { queryByText } = render(
      <Edit
        user={userStub}
        route={editRoute}
      />
    );

    expect(queryByText(/organisations/i)).not.toBeInTheDocument();
  });
});

const userStub = {
  uuid: 'c90933f6-7e16-4459-aafd-a717238da769',
  name: 'Ron',
  email: 'ron@swanson.com.au',
  password: 'xyz123',
  organisations: [],
  roles: [],
  claims: [],
};

const editRoute = '/api/admin/users/c90933f6-7e16-4459-aafd-a717238da769';
