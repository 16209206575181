import React from 'react';
import Layout from '../Layout';

const Dashboard = () => (
     <Layout>
        <div className="flex items-center">
            <div className="md:w-1/2 md:mx-auto">

                <div className="flex flex-col break-words bg-white rounded-lg shadow-lg">

                    <div className="w-full p-6">
                        <p className="text-gray-700">
                            Welcome, {window.User.name}!
                        </p>

                        <form method="POST" action="/logout">
                            <button type="submit"
                                    className="block w-32 mt-6 border-2 border-blue-500 hover:bg-blue-500 text-blue-500 hover:text-blue-100 font-semibold py-2 rounded focus:outline-none focus:shadow-outline">
                                Logout
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export default Dashboard;
