import React  from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ActiveToggle from '../../ActiveToggle';
import OrganisationForm from '../../Forms/OrganisationForm';
import useAjax from '../../hooks/useAjax';
import Layout from '../../Layout';
import Card from '../../Card';
import Roles from './Roles';
import Table from '../../Table';
import Icon from '../../Icon';
import Paginator from '../../Paginator';
import { Link as InertiaLink } from '@inertiajs/react'
import Search from '../../Search';
import Switch from '../../Switch';
import useSearch from '../../hooks/useSearch';

const Edit = (props) => {

  const organisation = props.organisation;
  const organisationAjax = useAjax(props.route);

  const onSubmit = data => {
    organisationAjax.save(data, organisation.uuid)
      .then(() => {
        toast('Organisation updated!', {
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const {search, setSearch} = useSearch({
    search: (new URLSearchParams(location.search)).get('search') || '',
    include_trashed: (new URLSearchParams(location.search)).get('include_trashed') === "true",
  });

  return <Layout>
    <div className="text-sm">
      <h2 className="text-2xl tracking-wide mb-10">{organisation.name}</h2>

      <div className="flex mb-8 -mx-2">
        <div className="w-1/3 px-2">
          <Card>
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-xl tracking-wide">Edit organisation</h3>

              {window.Claims.includes('login:manage-organisations') &&
                <ActiveToggle active={!organisation.deleted_at} route={`/api/admin/organisations/${organisation.uuid}/toggle-active`}/>
              }
            </div>
            <OrganisationForm organisation={organisation} onSubmit={onSubmit} loading={organisationAjax.loading} errors={organisationAjax.errors} />
          </Card>
        </div>
        <div className="w-1/3 px-2">
          <Roles organisation={organisation.uuid}/>
        </div>
      </div>

      <div className="py-6 flex justify-between text-sm">
        <div className="flex items-center">
          <Search onChange={value => setSearch('search', value)} value={search.search}/>
          <label className="ml-4 inline-flex items-center">
            <span className="mr-2 text-sm">Include deleted users</span>
            <Switch onChange={checked => setSearch('include_trashed', checked)}
                    value={search.include_trashed || false}
                    height={22}
                    width={43}/>
          </label>
        </div>
      </div>

      <div className="bg-white shadow-md rounded-lg mb-6 text-sm">

        <Table headers={['Name', 'Email', 'Roles']}>
          <tbody>
          {props.users.data.map((user, index) => (
            <tr key={index} className={`hover:bg-gray-100 focus-within:bg-gray-100 rounded-lg ${user.deleted_at ? 'text-gray-500' : ''}`}>
              <td className="border-t rounded-bl-lg">
                <InertiaLink href={`/admin/users/${user.uuid}/edit`}
                             className="px-6 py-4 flex items-center focus:text-blue-500 focus:outline-none">
                  <span>{user.name}</span>
                  {user.deleted_at &&
                  <span
                    className="ml-2 rounded-full px-2 text-xs text-red-600 bg-red-200">Inactive</span>
                  }
                  {!!user.is_service_account &&
                  <span className="ml-2 rounded-full px-2 text-xs text-blue-600 bg-blue-200">Service Account</span>
                  }
                </InertiaLink>
              </td>
              <td className="border-t">
                <InertiaLink tabIndex="-1"
                             href={`/admin/users/${user.uuid}/edit`}
                             className="px-6 py-4 flex items-center focus:outline-none">
                  {user.email}
                </InertiaLink>
              </td>
              <td className="border-t">
                <InertiaLink tabIndex="-1"
                             href={`/admin/users/${user.uuid}/edit`}
                             className="px-6 py-4 flex items-center focus:outline-none">
                  <span>{ user.roles.join(', ') }</span>
                </InertiaLink>
              </td>
              <td className="border-t w-px rounded-br-lg">
                <InertiaLink tabIndex="-1"
                             href={`/admin/users/${user.uuid}/edit`}
                             className="px-4 flex items-center focus:outline-none">
                  <Icon name="arrowRight" className="h-4 w-4"/>
                </InertiaLink>
              </td>
            </tr>
          ))}
          </tbody>
        </Table>
      </div>

      { props.users.meta.last_page !== 1 && <Paginator pagination={props.users.meta}/>}

      <ToastContainer/>
    </div>
  </Layout>;
};

Edit.defaultProps = {
  user: {},
  claims: null,
};

export default Edit;
