import React from 'react';

const usePopupCenter = ({url, title, height, width}) => {
  const top = window.outerHeight / 2 + window.screenY - ( height / 2)
  const left = window.outerWidth / 2 + window.screenX - ( width / 2)

  const newWindow =  window.open(url, title, `width=${width}, height=${height}, top=${top}, left=${left}`);

  if (window.focus) {
    newWindow.focus();
  }

  return newWindow;
};

export default usePopupCenter;