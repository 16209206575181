import React from 'react';
import { render, screen, act } from '@testing-library/react';
import NoMatches from './NoMatches';
import mockedAxios from 'axios';
import userEvent from '@testing-library/user-event';

jest.mock('axios', () => ({
  post: jest.fn().mockResolvedValue(),
}));

beforeEach(() => {
  window.axios = mockedAxios;
});

afterEach(() => {
  jest.clearAllMocks();
});

describe('Account/NoMatches', () => {
  it('renders', () => {
    render(<NoMatches provider="Test" email="test@mctest.com"/>);
  });
  
  it('allows you to create an account', async () => {
    render(<NoMatches provider="Test" email="test@mctest.com"/>);

    await act(async () => userEvent.click(screen.getByRole('button', {  name: /sign up for a free account/i})));

    expect(mockedAxios.post).toHaveBeenCalledTimes(1);
  });
  
  it('allows you to cancel', () => {
    render(<NoMatches provider="Test" email="test@mctest.com"/>);

    expect(screen.getByRole('link', {name: /go back to login/i})).toHaveAttribute('href', '/login/account');
  });
});